import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";
import { fetchAndStoreMyVehicleRepairs } from "./myDataService";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/vehicles";
const STORE_NAME = "repairs";
const MY_LOG_STORE_NAME = "my_vehicle_repair_logs";

export const fetchVehicleRepairs = async (vehicleId) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/repairs/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const addVehicleRepair = async (vehicleId, item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/repairs/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRepairs();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to add ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to add ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "addMyVehicleRepair",
      MY_LOG_STORE_NAME,
      item,
    });
    await db.addItem(MY_LOG_STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create repair history record offline.`,
    });
  }
};

export const editVehicleRepair = async (vehicleId, item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/repairs/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRepairs();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "editMyVehicleRepair",
      STORE_NAME,
      item,
    });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create repair history record offline.`,
    });
  }
};

export const deleteVehicleRepair = async (
  vehicleId,
  item,
  isOthers = false
) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${vehicleId}/repairs/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyVehicleRepairs();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "deleteMyVehicleRepair",
      MY_LOG_STORE_NAME,
      item,
    });
    await db.deleteItem(MY_LOG_STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot delete repair history offline.`,
    });
  }
};

export const syncOfflineVehicleRepairChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addMyVehicleRepair") {
        await addVehicleRepair(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editMyVehicleRepair") {
        await editVehicleRepair(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteMyVehicleRepair") {
        await deleteVehicleRepair(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};
